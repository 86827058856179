import React, { useEffect, useState } from 'react';
import './Wonglinks.css';

const Wonglinks = () => {
  const [isModalOpen, setIsModalOpen] = useState(true); // Set the modal to open by default

  // Ensure the modal closes when the overlay or close button is clicked
  const toggleModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="beacon-container">
      <div className="header">
        <button className="subscribe-button">Subscribe</button>
        <button className="share-button">Share</button>
      </div>

      <div className="profile-section">
        <img
          src="https://cdn.prod.website-files.com/666cc36fb532af19d57ce7fa/66f456e2099bf9afef713e55_124355.png" // Replace with actual image URL
          alt="Profile"
          className="profile-pic"
        />
        <h1 className="username">WongLinks</h1>
        <p className="subtitle">Welcome to the Wong-Ma Ecosystem</p>
      </div>

      <div className="content-section">
        <div className="button-section">
          <a href="#" className="button">WONGCLOUD</a>
          <a href="#" className="button">WONGTUBE</a>
          <a href="#" className="button">WONGAI</a>
          <a href="#" className="button">WONGSTREAM</a>
          <a href="#" className="button">WONGANDWHITE</a>
        </div>
      </div>

      {/* Video Modal Section */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={toggleModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <iframe
              width="100%"
              height="400"
              src="https://www.youtube.com/embed/GegHA-_9-UQ?autoplay=1" // Replace 'your-video-id' with the actual video ID
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default Wonglinks;
