import React from 'react';
import './App.css';
import Wonglinks from './Wonglinks';

function App() {
  return (
    <div className="App">
      {/* Adding the MailIntakeForm component */}
        <Wonglinks/>
    </div>

  );
}

export default App;
